import React, { Component, createRef } from "react";
// компоненты из библиотек:
import { ToastContainer, toast } from "react-toastify";
// cтили:
import styles from "./MainPage.module.css";
import "react-toastify/dist/ReactToastify.css";
// компоненты созданные:
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ButtonHideTheory from "../../components/ButtonHideTheory/ButtonHideTheory";
import TheoryAndTaskOrLayout from "../../components/TheoryAndTaskOrLayout/TheoryAndTaskOrLayout";
import FrameComponent from "../../components/FrameComponent/FrameComponent";
import TaskButtons from "../../components/TaskButtons/TaskButtons";
import CongratsModal from "../../components/CongratsModal/CongratsModal";
import ErrorStub from "../../components/ErrorStub/ErrorStub";
import ErrorInvalidTokenOrBlock from "../../components/ErrorInvalidTokenOrBlock/ErrorInvalidTokenOrBlock";
import BrowserHeader from "../../components/BrowserHeader/BrowserHeader";
import BrowserResultModal from "../../components/BrowserResultModal/BrowserResultModal";
import ErrorServerStub from "../../components/ErrorServerStub/ErrorServerStub";
import CodeEditors from "../../components/CodeEditors/CodeEditors";
import ModalRefreshQuestion from "../../components/ModalRefreshQuestion/ModalRefreshQuestion";
import ModalAutotranslator from "../../components/ModalAutotranslator/ModalAutotranslator";
import ModalTaskCriteria from "../../components/ModalTaskCriteria/ModalTaskCriteria";
import ModalShareWithFriend from "../../components/ModalShareWithFriend/ModalShareWithFriend";
import MobileAndTabletStub from "../../components/MobileAndTabletStub/MobileAndTabletStub";
import Loader from "../../components/Loader/Loader";
import Speedometer from "../../components/Speedometer/Speedometer";
import LeftSidePanel from "../../components/LeftSidePanel/LeftSidePanel";
import ModalIntroductionInfo from "../../components/ModalIntroductionInfo/ModalIntroductionInfo";
import ErrorServerRefreshStub from "../../components/ErrorServerRefreshStub/ErrorServerRefreshStub";
import ErrorEndedLives from "../../components/ErrorEndedLives/ErrorEndedLives";
import ModalProgress from "../../components/ModalProgress/ModalProgress";
import ModalInvitedFriend from "../../components/ModalInvitedFriend/ModalInvitedFriend";
import ModalRefInvited from "../../components/ModalRefInvited/ModalRefInvited";
// прочие вспомогательные инструменты:
import * as API from "../../services/api";
import { FormattedMessage } from "react-intl";
// import localization from "../../utils/localization";
import languages from "../../languages";
import Context from "../../context/context";
// стили для codemirror
import "codemirror/lib/codemirror.css";
import "../../utils/materialCodeMirror.css";
import "codemirror/theme/neat.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/htmlmixed/htmlmixed";
const timerDelayInMs = 600000; // 10 minutes
// const timerDelayInMs = 10000;

class MainPage extends Component {
  static contextType = Context;
  state = {
    userGameVariable: {
      currentScore: "",
      currentLiveCount: 0,
      invitedFriendCount: 0,
      isFirstSiteOpening: false,
      isInvitedByFriend: false,
    },
    telegramUserInfo: {
      userId: "",
      fromUserId: "",
      userName: "",
      languageCode: "",
      firstName: "",
      lastName: "",
      registerDate: "",
      phone: "",
      email: "",
      eventDate: "",
      tag: "",
      dailyNotifyHour: "",
      metadata: "",
    },
    isUserAgreeWithGameRules: false,
    isShowErrorEndedLives: false,
    isBonusLinkedinAvailable: false,
    currentTaskId: "",
    blockTasksArr: "",
    token: "",
    block: "",
    valueHTML: "",
    valueCSS: "",
    compiledValueCss: "",
    htmlDescription: "",
    metadata: "",
    passed: "", // выполнена ли текущая задача или нет
    passedTasks: [],
    blockIndex: 0, // это номер дня марафоне в хедере и в поздравительной модалке
    // появление пропадание теории с заданием:
    isTheoryAndTaskShow: true,
    // модальное окно с поздравлениями:
    isCongratsModalOpen: false,
    isModalProgressOpen: false,
    // для отображения результата взаимодействия html и css:
    resultView: { __html: "" },
    // время старта для таймера:
    timerEndTime: Date.now() + timerDelayInMs,
    // показываем ли таймер на текущем вопросе:
    showTimer: true,
    // показать компонент ошибки (не корректная ссылка или блок в query parametrs строки или юзер не участник марафона):
    errorShow: false,
    // показать компонент ошибки когда токен или блок не валиден:
    errorInvalidTokenOrBlock: false,
    // для фиксации времени прохождения пользователем текущего вопроса:
    startTime: null,
    showBrowserResultModal: false,
    // буль на случай когда лежит сервер
    isServerError: false,
    // для модальных окон после 5ти дней о том есть ли нерешенные задания в каких то днях или марафон полностью завершен
    nonFinishedDays: null,
    finishedLastDay: false,
    passedAllTasks: false,
    // лоадер
    showLoader: false,

    // для подсветки или отключения конкретных строчек в редакторе кода
    notEditableHtmlBlocks: [],
    notEditableCssBlocks: [],
    htmlHlLines: [],
    cssHlLines: [],

    // countShowModalBlockResultOnThisQuestion: 0,
    isRefreshCurrentQuestion: false,

    htmlCursor: null,

    showModalRefreshQuestion: false,
    showModalAutotranslator: false,
    showModalTaskCriteria: false,

    isShowModalIntroductionInfo: null,

    successConditions: [],
    failedConditions: [],
    taskSuccessfullyPassed: null,

    isMobileViewSelected: false,
    isTabletViewSelected: false,
    isShowErrorServerRefreshStub: false,
    speedResult: null,
    isShowLeftSidePanel: false,
    flagForLevel: {
      preTrainee: false,
      trainee: false,
      preJunior: false,
      junior: false,
    },
    seenVideoHelpTaskIdArray: [],
    isShowModalShareWithFriend: false,
    isShowModalInvitedFriend: false,
    isModalRefInvited: false,
    videoEducation: { 1: false, 2: false, 3: false, 4: false },
  };

  theoryAndTaskRef = createRef();
  // refs for translateModal:
  headerTextLogoRef = createRef();
  headerTextQuestionRef = createRef();
  footerTextRef = createRef();

  async componentDidMount() {
    const { location, clientWidth } = this.props;
    const { valueHTML, valueCSS } = this.state;
    const token = new URLSearchParams(location.search).get("token");
    if (
      location.search &&
      clientWidth > 900 &&
      valueHTML === "" &&
      valueCSS === ""
    ) {
      // const token = new URLSearchParams(location.search).get("token");
      const block = new URLSearchParams(location.search).get("block");
      this.getCurrentBlockAndCurrentTask(token, block);
      await this.getUserGameVariable(token);
      this.getUserBlocksProgress(token);
      this.getTelegramUserInfo(token);
    } else {
      this.setState({ errorShow: true });
    }

    this.getLevelFromStorage();
    this.getRefUserFriendsFromStorage();
    // monitoring availability autotranslator - start
    setTimeout(() => {
      this.checkAvailabilityAutotranslator();
    }, 2000);

    // monitoring availability autotranslator - end
  }

  async componentDidUpdate(prevProps, prevState) {
    const { valueHTML, valueCSS, currentTaskId } = this.state;
    const { clientWidth } = this.props;
    if (
      (prevState.valueCSS !== valueCSS && clientWidth > 900) ||
      (prevState.valueHTML !== valueHTML && clientWidth > 900) ||
      (prevState.currentTaskId !== currentTaskId && clientWidth > 900)
    ) {
      // crutch for task html-auto-hw-1-16 - start:
      const moduleOneTaskIdSixteen = "html-auto-hw-1-16";
      const stub = `<a href="#">
      <img src="" 
           alt="logo">
    </a>`;
      const partOfСorrectAnswer = "../images/logo.png";
      if (currentTaskId === moduleOneTaskIdSixteen) {
        this.setState(
          {
            resultView: {
              __html: valueHTML.includes(partOfСorrectAnswer)
                ? valueHTML
                : stub,
            },
          },
          () => {
            this.addEventPreventDefaultForLinkInResultContainer();
          }
        );
      } else {
        // crutch for task html-auto-hw-1-16 - end
        this.setState(
          {
            resultView: { __html: valueHTML },
          },
          () => {
            this.addEventPreventDefaultForLinkInResultContainer();
          }
        );
      }
    }
    const { currentScore, invitedFriendCount } = this.state.userGameVariable;
    if (prevState.userGameVariable.currentScore !== currentScore) {
      await this.getLevelFromStorage();
      this.takeLevel();
    }
    if (prevState.userGameVariable.invitedFriendCount !== invitedFriendCount) {
      this.getRefUserFriendsFromStorage();
    }

    const { blockTasksArr } = this.state;
    if (
      prevState.clientWidth !== clientWidth &&
      prevState.clientWidth < 900 &&
      blockTasksArr === "" &&
      valueHTML === "" &&
      valueCSS === ""
    ) {
      const { location } = this.props;
      if (location.search) {
        const token = new URLSearchParams(location.search).get("token");
        const block = new URLSearchParams(location.search).get("block");
        this.getCurrentBlockAndCurrentTask(token, block);
      } else {
        this.setState({ errorShow: true });
      }
    }
  }

  hideTimer = () => {
    this.setState({ showTimer: false });
  };

  forHighligthExampleInTheoryAndTaskSection = () => {
    document.querySelectorAll("pre code").forEach((block) => {
      window.hljs.highlightBlock(block);
    });
  };

  handleChangeHMTL = (value) => {
    this.setState({ valueHTML: value });
  };
  handleChangeCSS = (value) => {
    this.setState({ valueCSS: value });
  };

  showLeftSidePanel = () => {
    this.setState({ isShowLeftSidePanel: true });
  };
  hideLeftSidePanel = () => {
    this.setState({ isShowLeftSidePanel: false });
  };

  showModalShareWithFriend = () => {
    this.setState({ isShowModalShareWithFriend: true });
  };
  hideModalShareWithFriend = () => {
    this.setState({ isShowModalShareWithFriend: false });
  };

  showModalIntroductionInfo = () => {
    this.setState({ isShowModalIntroductionInfo: true });
  };
  hideModalIntroductionInfo = () => {
    this.setState({ isShowModalIntroductionInfo: false });
  };
  handleChangeUserAgreeWithGameRules = (e) => {
    this.setState({ isUserAgreeWithGameRules: e.target.checked });
  };

  getTelegramUserInfo = (token) => {
    toast.dismiss();
    const { lang } = this.context;
    this.setState({ showLoader: true });
    API.getTelegramUserInfo(token)
      .then((res) => {
        if (res.data.registerDate) {
          this.setState({
            telegramUserInfo: res.data,
          });
        } else if (res.data.success === false) {
          toast.error(languages[lang].ToastifyFunction_isNotTelegramUser, {
            autoClose: 10000,
          });
        }
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  startGameOrContinue = () => {
    toast.dismiss();
    const { lang } = this.context;
    const { isUserAgreeWithGameRules, userGameVariable, token } = this.state;

    if (!isUserAgreeWithGameRules) {
      toast.error(languages[lang].toastifyFunction_error, {
        autoClose: 8000,
      });
    } else if (userGameVariable.isFirstSiteOpening === "true") {
      API.userOpenedSiteRequest(token);
      this.setState((prevState) => ({
        userGameVariable: {
          ...prevState.userGameVariable,
          isFirstSiteOpening: false,
        },
      }));
      this.hideModalIntroductionInfo();
    } else {
      this.hideModalIntroductionInfo();
    }
  };

  getCurrentBlockAndCurrentTask = (token, block) => {
    API.getTasksBlockAndCurrentTask(token, block)
      .then((res) => {
        if (res.data.success) {
          if (
            (res.data.blockIndex === 5 && res.data.passedTasks.length > 0) ||
            res.data.blockIndex === 6
          ) {
            this.setState({ isBonusLinkedinAvailable: true });
          } else {
            this.setState({ isBonusLinkedinAvailable: false });
          }
          this.setState(
            {
              currentTaskId: res.data.currentTask,
              blockTasksArr: res.data.blockTasks,
              token: token,
              passedTasks: res.data.passedTasks,
              blockIndex: res.data.blockIndex,
              errorInvalidTokenOrBlock: false,
              errorShow: false,
              isServerError: false,
              block: block,
            },
            () => {
              this.getTask();
            }
          );
        } else {
          this.setState({ errorInvalidTokenOrBlock: true });
          toast.error(res.data.message, {
            autoClose: 4000,
          });
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      });
  };

  getTask = () => {
    const { token, currentTaskId } = this.state;
    toast.dismiss();

    this.setState((prevState) => ({
      showLoader: true,
      passedTasks: prevState.passedTasks.includes(currentTaskId)
        ? [...prevState.passedTasks]
        : [...prevState.passedTasks, currentTaskId],
    }));
    API.getTaskInfo(token, currentTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              startTime: Date.now(),
              notEditableHtmlBlocks: res.data.notEditableHtmlBlocks,
              notEditableCssBlocks: res.data.notEditableCssBlocks,
              htmlHlLines: res.data.htmlHlLines,
              cssHlLines: res.data.cssHlLines,
              isServerError: false,
              htmlCursor: res.data.htmlCursor,
              cssCursor: res.data.cssCursor,
              compiledValueCss: "",
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
              // this.changeBackgroundInCodemirrorWithHtml();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  checkTest = async () => {
    const {
      token,
      currentTaskId,
      valueHTML,
      valueCSS,
      startTime,
      blockTasksArr,
      block,
      videoEducation,
    } = this.state;

    const solveTimeSeconds = Math.round((Date.now() - startTime) / 1000);

    const isLastDayQuestion =
      blockTasksArr.indexOf(currentTaskId) === blockTasksArr.length - 1
        ? true
        : false;

    const isThirdTask =
      block === "juf31geq" && currentTaskId === "html-3" ? true : false;

    try {
      this.toogleLoader(true);
      const res = await API.checkTest(
        token,
        currentTaskId,
        valueHTML,
        valueCSS,
        solveTimeSeconds
      );
      const metaData = res.data.metadata?.compiledCss
        ? res.data.metadata.compiledCss
        : "";
      this.setState(
        {
          isServerError: false,
          successConditions: res.data.successConditions,
          failedConditions: res.data.failedConditions,
          taskSuccessfullyPassed: res.data.taskSuccessfullyPassed,
          compiledValueCss: metaData,
        },

        () => {
          if (isThirdTask && this.state.taskSuccessfullyPassed) {
            this.getTotalProgress(token, currentTaskId);
            this.openCongratsModal();
            this.callToastifyTaskSuccesses();
          } else if (isLastDayQuestion && this.state.taskSuccessfullyPassed) {
            this.getTotalProgress(token, currentTaskId);
            this.openCongratsModal();
            this.callToastifyTaskSuccesses();
            if (block === "juf31geq") {
              this.setState({ videoEducation: { ...videoEducation, 1: true } });
            }
            if (block === "74gfhj7l") {
              this.setState({ videoEducation: { ...videoEducation, 2: true } });
            }
            if (block === "jgfhk855") {
              this.setState({ videoEducation: { ...videoEducation, 3: true } });
            }
            if (block === "f5368942") {
              this.setState({ videoEducation: { ...videoEducation, 4: true } });
            }
            if (block === "fsa64rij") {
              this.setState({ isBonusLinkedinAvailable: true });
            }
          } else {
            if (this.state.taskSuccessfullyPassed) {
              this.callToastifyTaskSuccesses();
            }
          }

          this.openModalTaskCriteria();
          this.getUserGameVariable(token);
        }
      );
    } catch (error) {
      this.setState({ isServerError: true });
    } finally {
      if (isLastDayQuestion && this.state.taskSuccessfullyPassed) {
        this.toogleLoader(true);
      } else if (isThirdTask && this.state.taskSuccessfullyPassed) {
        this.toogleLoader(true);
      } else {
        this.toogleLoader(false);
      }
    }
  };

  openCongratsModal = () => {
    this.setState({ isCongratsModalOpen: true });
  };
  closeCongratsModal = () => this.setState({ isCongratsModalOpen: false });

  getNextTask = () => {
    toast.dismiss();
    const { token, currentTaskId, blockTasksArr } = this.state;
    const nextTaskId =
      blockTasksArr.indexOf(currentTaskId) !== blockTasksArr.length - 1
        ? blockTasksArr[blockTasksArr.indexOf(currentTaskId) + 1]
        : blockTasksArr[0];

    this.setState((prevState) => ({
      showLoader: true,
      passedTasks: prevState.passedTasks.includes(currentTaskId)
        ? [...prevState.passedTasks]
        : [...prevState.passedTasks, currentTaskId],
    }));
    API.getTaskInfo(token, nextTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              currentTaskId: res.data.taskId,
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              timerEndTime: Date.now() + timerDelayInMs,
              showTimer: true,
              startTime: Date.now(),
              notEditableHtmlBlocks: res.data.notEditableHtmlBlocks,
              notEditableCssBlocks: res.data.notEditableCssBlocks,
              htmlHlLines: res.data.htmlHlLines,
              cssHlLines: res.data.cssHlLines,
              isServerError: false,
              htmlCursor: res.data.htmlCursor,
              cssCursor: res.data.cssCursor,
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  getPrevTask = () => {
    toast.dismiss();

    const { token, currentTaskId, blockTasksArr } = this.state;
    const nextTaskId =
      blockTasksArr.indexOf(currentTaskId) !== 1
        ? blockTasksArr[blockTasksArr.indexOf(currentTaskId) - 1]
        : blockTasksArr[0];

    this.setState({ showLoader: true });
    API.getTaskInfo(token, nextTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              currentTaskId: res.data.taskId,
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              timerEndTime: Date.now() + timerDelayInMs,
              showTimer: true,
              startTime: Date.now(),
              notEditableHtmlBlocks: res.data.notEditableHtmlBlocks,
              notEditableCssBlocks: res.data.notEditableCssBlocks,
              htmlHlLines: res.data.htmlHlLines,
              cssHlLines: res.data.cssHlLines,
              isServerError: false,
              htmlCursor: res.data.htmlCursor,
              cssCursor: res.data.cssCursor,
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  toggleTheoryAndTask = () => {
    this.setState(
      (prevState) => ({
        isTheoryAndTaskShow: !prevState.isTheoryAndTaskShow,
      }),
      () => this.forHighligthExampleInTheoryAndTaskSection()
    );
  };

  callToastify = () => {
    const { passed, metadata } = this.state;
    if (passed) {
      toast.success(<FormattedMessage id="callToastify_taskAlreadySolved" />, {
        autoClose: 4000,
      });
    }

    if (
      metadata.disableHtmlEditor &&
      metadata.disableCssEditor &&
      metadata.needHTML &&
      metadata.needCss &&
      metadata.needCode
    ) {
      toast.warn(<FormattedMessage id="callToastify_text1" />, {
        autoClose: 7000,
      });
    }
    if (
      metadata.disableHtmlEditor &&
      metadata.disableCodeEditor &&
      metadata.needHTML &&
      metadata.needCss &&
      metadata.needCode
    ) {
      toast.warn(<FormattedMessage id="callToastify_text2" />, {
        autoClose: 7000,
      });
    }
    if (
      metadata.disableCssEditor &&
      metadata.disableCodeEditor &&
      metadata.needHTML &&
      metadata.needCss &&
      metadata.needCode
    ) {
      toast.warn(<FormattedMessage id="callToastify_text3" />, {
        autoClose: 7000,
      });
    }

    if (metadata.disableHtmlEditor && !metadata.needCode) {
      toast.warn(<FormattedMessage id="callToastify_text4" />, {
        autoClose: 7000,
      });
    }
    if (metadata.disableCssEditor && !metadata.needCode && metadata.needCss) {
      toast.warn(<FormattedMessage id="callToastify_text5" />, {
        autoClose: 7000,
      });
    }
    if (
      !metadata.needCss &&
      metadata.needHTML &&
      metadata.needCode &&
      metadata.disableHtmlEditor
    ) {
      toast.warn(<FormattedMessage id="callToastify_text6" />, {
        autoClose: 7000,
      });
    }
  };

  refreshCurrentQuestion = () => {
    const { token, currentTaskId } = this.state;
    toast.dismiss();

    this.setState({ showLoader: true });
    API.getTaskInfo(token, currentTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              notEditableHtmlBlocks: res.data.notEditableHtmlBlocks,
              notEditableCssBlocks: res.data.notEditableCssBlocks,
              htmlHlLines: res.data.htmlHlLines,
              cssHlLines: res.data.cssHlLines,
              isServerError: false,
              htmlCursor: res.data.htmlCursor,
              cssCursor: res.data.cssCursor,
              compiledValueCss: "",
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.setState({ isRefreshCurrentQuestion: true });
              this.cleanIframeInBigAndSmallBrowser();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .then(() => {
        this.setState({ isRefreshCurrentQuestion: false });
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };
  cleanIframeInBigAndSmallBrowser = () => {
    // clean in small browser - start
    document
      .querySelector("iframe")
      .contentDocument.querySelector("body").innerHTML = "";

    document
      .querySelector("iframe")
      .contentDocument.querySelector("body").innerHTML = `<div>
      <style>${this.state.valueCSS}</style>
      <div dangerouslySetInnerHTML=${this.state.resultView} />
    </div>`;
    // clean in small browser - end

    // clean in big browser - start
    if (document.querySelector("#browser-result-container")) {
      document
        .querySelector("#browser-result-container")
        .querySelector("iframe")
        .contentDocument.querySelector("body").innerHTML = "";

      document
        .querySelector("#browser-result-container")
        .querySelector("iframe")
        .contentDocument.querySelector("body").innerHTML = `<div>
      <style>${this.state.valueCSS}</style>
      <div dangerouslySetInnerHTML=${this.state.resultView} />
    </div>`;
    }
    // clean in big browser - end
  };

  openBrowserResultModal = () => {
    this.setState({ showBrowserResultModal: true });
  };
  closeBrowserResultModal = () => {
    this.setState({ showBrowserResultModal: false });
  };

  changeBackgroundInCodemirrorWithHtml = () => {
    const { metadata } = this.state;
    if (metadata && metadata.disableHtmlEditor) {
      if (!document.querySelector("#html_box")) return;
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror").style.backgroundColor = "rgb(27, 57, 94)";
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgb(27, 57, 94)";
    } else if (metadata && !metadata.disableHtmlEditor) {
      // console.log("#html_box work active");
      if (!document.querySelector("#html_box")) return;
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
    }

    if (metadata && metadata.disableCssEditor) {
      if (!document.querySelector("#css_box")) return;
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror").style.backgroundColor = "rgb(27, 57, 94)";
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgb(27, 57, 94)";
    } else if (metadata && !metadata.disableCssEditor) {
      if (!document.querySelector("#css_box")) return;
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
    }
  };

  addEventPreventDefaultForLinkInResultContainer = () => {
    const { currentTaskId } = this.state;
    if (
      currentTaskId === "css-36" ||
      currentTaskId === "css-netlify" ||
      currentTaskId === "css-auto-hw-8-4" ||
      currentTaskId === "css-auto-hw-8-5"
    )
      return;
    document
      .querySelector("iframe")
      .contentDocument.querySelectorAll("a")
      .forEach((el) =>
        el.addEventListener("click", function (e) {
          e.preventDefault();
          return false;
        })
      );
  };

  openModalRefreshQuestion = () => {
    this.setState({ showModalRefreshQuestion: true });
  };
  closeModalRefreshQuestion = () => {
    this.setState({ showModalRefreshQuestion: false });
  };

  checkAvailabilityAutotranslator = () => {
    const { lang } = this.context;
    if (
      !this.headerTextLogoRef.current ||
      !this.headerTextQuestionRef.current ||
      !this.footerTextRef.current
    )
      return;

    if (
      !this.headerTextLogoRef.current.textContent.includes(
        languages[lang].header_logoName
      ) ||
      !this.headerTextQuestionRef.current.textContent.includes(
        languages[lang].header_question
      ) ||
      !this.footerTextRef.current.textContent.includes(
        languages[lang].footer_text
      )
    ) {
      this.openModalAutotranslator();
    }
  };
  openModalAutotranslator = () => {
    this.setState({ showModalAutotranslator: true });
  };
  closeModalAutotranslator = () => {
    this.setState({ showModalAutotranslator: false });
  };

  activityTimeMonitoringInCodeEditor = () => {
    const { startTime } = this.state;
    const thirtyMinutesInMs = 1800000;
    if (Date.now() - startTime >= thirtyMinutesInMs) {
      this.setState({ startTime: Date.now() });
    }
  };

  openModalTaskCriteria = () => {
    this.setState({ showModalTaskCriteria: true });
  };
  closeModalTaskCriteria = () => {
    this.setState({ showModalTaskCriteria: false });
  };

  getSpecificTask = (taskId) => {
    toast.dismiss();
    const { token, language } = this.state;

    this.setState({ showLoader: true });
    API.getTaskInfo(token, taskId, language)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              valueHTML: res.data.initialHtml,
              valueCSS: res.data.initialCss,
              currentTaskId: res.data.taskId,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              timerEndTime: Date.now() + timerDelayInMs,
              showTimer: true,
              startTime: Date.now(),
              isServerError: false,
              compiledValueCss: "",
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  selectMobileView = () => {
    this.setState({ isMobileViewSelected: true, isTabletViewSelected: false });
  };
  cancelSelectMobileView = () => {
    this.setState({ isMobileViewSelected: false });
  };
  selectTabletView = () => {
    this.setState({ isTabletViewSelected: true, isMobileViewSelected: false });
  };
  cancelTabletView = () => {
    this.setState({ isTabletViewSelected: false });
  };

  getTotalProgress = async (token, currentTaskId) => {
    try {
      this.toogleLoader(true);
      const result = await API.getTaskStatisticsRequest(token, currentTaskId);
      this.setState({ speedResult: result.data.betterThanPercents });
    } catch (error) {
    } finally {
      this.toogleLoader(false);
    }
  };

  getUserBlocksProgress = async (token) => {
    try {
      const result = await API.userGetBlocksProgress(token);
      const video = { 1: false, 2: false, 3: false, 4: false };
      if (
        result.data["juf31geq"].solvedTaskIds.length ===
        result.data["juf31geq"].taskIds.length
      ) {
        video["1"] = true;
      }
      if (
        result.data["74gfhj7l"].solvedTaskIds.length ===
        result.data["74gfhj7l"].taskIds.length
      ) {
        video["2"] = true;
      }
      if (
        result.data["jgfhk855"].solvedTaskIds.length ===
        result.data["jgfhk855"].taskIds.length
      ) {
        video["3"] = true;
      }
      if (
        result.data["f5368942"].solvedTaskIds.length ===
        result.data["f5368942"].taskIds.length
      ) {
        video["4"] = true;
      }
      this.setState({ videoEducation: video });
    } catch (error) {}
  };

  getUserGameVariable = async (token) => {
    try {
      const result = await API.getUserGameVariable(token);
      if (Number(result.data.frontendMarathonLiveCount) === 0) {
        this.setState({
          isShowErrorEndedLives: true,
        });
      }
      this.setState((prevState) => ({
        userGameVariable: {
          ...prevState.userGameVariable,
          currentScore: result.data.frontendMarathonScoreCount,
          currentLiveCount: +result.data.frontendMarathonLiveCount,
          invitedFriendCount: result.data.frontendMarathonInvitedFriendCount,
          isFirstSiteOpening: result.data.frontendMarathonIsFirstSiteOpening,
          isInvitedByFriend: result.data.frontendMarathonIsInvitedByFriend,
          seenVideoHelpTaskIdArray: [...Object.keys(result.data)],
        },
      }));
      if (
        result.data.frontendMarathonIsFirstSiteOpening === "true" &&
        result.data.frontendMarathonIsInvitedByFriend === "true"
      ) {
        this.showModalRefInvited();
      }
    } catch (error) {}
  };

  openModalProgress = () => {
    this.setState({
      isModalProgressOpen: true,
    });
  };
  closeModalProgress = () => {
    const { currentScore } = this.state.userGameVariable;
    if (currentScore >= 54 && currentScore < 60) {
      const level = {
        preTrainee: true,
        trainee: false,
        preJunior: false,
        junior: false,
      };
      localStorage.setItem("level", JSON.stringify(level));
    }
    if (currentScore >= 60 && currentScore < 81) {
      const level = {
        preTrainee: true,
        trainee: true,
        preJunior: false,
        junior: false,
      };
      localStorage.setItem("level", JSON.stringify(level));
    }
    if (currentScore >= 81 && currentScore < 90) {
      const level = {
        preTrainee: true,
        trainee: true,
        preJunior: true,
        junior: false,
      };
      localStorage.setItem("level", JSON.stringify(level));
    }
    if (currentScore >= 90) {
      const level = {
        preTrainee: true,
        trainee: false,
        preJunior: true,
        junior: true,
      };
      localStorage.setItem("level", JSON.stringify(level));
    }
    this.setState({
      isModalProgressOpen: false,
    });
  };

  setStartLevelToStorage = () => {
    const level = {
      preTranee: false,
      trainee: false,
      preJunior: false,
      junior: false,
    };
    localStorage.setItem("level", JSON.stringify(level));
  };

  getLevelFromStorage = async () => {
    const storage = await JSON.parse(localStorage.getItem("level"));
    if (storage) {
      this.setState({
        flagForLevel: storage,
      });
    } else {
      this.setStartLevelToStorage();
    }
  };

  showModalInvitedFriends = () => {
    this.setState({
      isShowModalInvitedFriend: true,
    });
  };

  hideModalInvitedFriends = () => {
    const { invitedFriendCount } = this.state.userGameVariable;
    this.setState({
      isShowModalInvitedFriend: false,
    });
    localStorage.setItem("friendsRef", JSON.stringify(invitedFriendCount));
  };
  closeModalRefInvited = () => {
    this.setState({ isModalRefInvited: false });
  };

  showModalRefInvited = () => {
    this.setState({ isModalRefInvited: true });
  };

  getRefUserFriendsFromStorage = async () => {
    const { invitedFriendCount } = this.state.userGameVariable;
    const storage = await JSON.parse(localStorage.getItem("friendsRef"));
    if (storage) {
      if (invitedFriendCount > +storage) {
        this.showModalInvitedFriends();
      }
    } else {
      localStorage.setItem("friendsRef", JSON.stringify(invitedFriendCount));
    }
  };

  takeLevel = () => {
    this.getLevelFromStorage();
    const { preTrainee, trainee, preJunior, junior } = this.state.flagForLevel;
    const { currentScore } = this.state.userGameVariable;
    if (currentScore >= 54 && currentScore < 60 && !preTrainee) {
      this.openModalProgress();
    }
    if (currentScore >= 60 && currentScore < 90 && !trainee) {
      this.openModalProgress();
    }
    if (currentScore >= 81 && currentScore < 90 && !preJunior) {
      this.openModalProgress();
    }
    if (currentScore >= 90 && !junior) {
      this.openModalProgress();
    }
  };

  takeAwayOnePointFromUserАForViewingVideohint = () => {
    const { currentTaskId } = this.state;
    this.setState((prevState) => ({
      userGameVariable: {
        ...prevState.userGameVariable,
        currentScore: prevState.userGameVariable.currentScore - 1,
        seenVideoHelpTaskIdArray: [
          ...prevState.userGameVariable.seenVideoHelpTaskIdArray,
          currentTaskId,
        ],
      },
    }));
  };

  callToastifyTaskSuccesses = () => {
    const { lang } = this.context;
    const index = Math.floor(Math.random() * Math.floor(10));
    let arrToastify;
    arrToastify = [
      languages[lang].taskSuccessesNotification1,
      languages[lang].taskSuccessesNotification2,
      languages[lang].taskSuccessesNotification3,
      languages[lang].taskSuccessesNotification4,
      languages[lang].taskSuccessesNotification5,
      languages[lang].taskSuccessesNotification6,
      languages[lang].taskSuccessesNotification7,
      languages[lang].taskSuccessesNotification8,
      languages[lang].taskSuccessesNotification9,
      languages[lang].taskSuccessesNotification10,
    ];
    toast.success(arrToastify[index], {
      autoClose: 5000,
    });
  };

  toogleLoader = (status) => this.setState({ showLoader: status });

  render() {
    // ЕСЛИ ЧЕЛОВЕК зашел с устройства с экраном меньше 900pх то не показывать ему ничего кроме заглушки:
    const { clientWidth } = this.props;
    if (clientWidth < 900) {
      return <MobileAndTabletStub />;
    }

    // if the server reboots, you need to wait 1-2 minutes and reload the page:
    const { isShowErrorServerRefreshStub } = this.state;
    if (isShowErrorServerRefreshStub) {
      return <ErrorServerRefreshStub />;
    }

    // если не работает сервер, заглушка
    const { isServerError } = this.state;
    if (isServerError) {
      return <ErrorServerStub />;
    }

    // если не валиден token или block:
    const { errorInvalidTokenOrBlock } = this.state;
    if (errorInvalidTokenOrBlock) {
      return (
        <>
          <ToastContainer />
          <ErrorInvalidTokenOrBlock />
        </>
      );
    }

    // Если в url нету query parameters:
    const { errorShow } = this.state;
    if (errorShow) {
      return <ErrorStub />;
    }

    //  меняем беграунд html редактора когда он должен быть неактивным на редактирование
    const { metadata } = this.state;
    if (
      metadata &&
      (metadata.disableHtmlEditor ||
        metadata.disableCssEditor ||
        metadata.disableCodeEditor) &&
      clientWidth > 900
    ) {
      this.changeBackgroundInCodemirrorWithHtml();
    }

    const {
      block,
      token,
      valueHTML,
      valueCSS,
      blockTasksArr,
      currentTaskId,
      // для модального окна с результатами:
      passed,
      // isTheoryAndTaskShow
      isTheoryAndTaskShow,
      isCongratsModalOpen,
      resultView,
      // время для таймера:
      timerEndTime,
      showTimer,
      // номер дня марафона в хедере
      blockIndex,
      showBrowserResultModal,
      // nonFinishedDays,
      showLoader,
      htmlDescription,

      notEditableHtmlBlocks,
      notEditableCssBlocks,
      htmlHlLines,
      cssHlLines,

      isRefreshCurrentQuestion,
      htmlCursor,
      cssCursor,

      showModalRefreshQuestion,
      showModalAutotranslator,
      showModalTaskCriteria,

      successConditions,
      failedConditions,
      taskSuccessfullyPassed,

      passedTasks,
      isMobileViewSelected,
      isTabletViewSelected,

      compiledValueCss,
      speedResult,
      userGameVariable,
      isShowLeftSidePanel,
      isShowModalIntroductionInfo,
      telegramUserInfo,
      isUserAgreeWithGameRules,
      isShowErrorEndedLives,
      isModalProgressOpen,
      isBonusLinkedinAvailable,
      isShowModalShareWithFriend,
      isShowModalInvitedFriend,
      isModalRefInvited,
      videoEducation,
    } = this.state;

    const widthCodeAndResultSection = isTheoryAndTaskShow
      ? (metadata.needCss && metadata.needCode) ||
        metadata.needCss ||
        metadata.needCode
        ? "68.5%"
        : "58.5%"
      : "94%";
    // const widthCodeAndResultSection = isTheoryAndTaskShow ? "67%" : "94%";
    const widthTheoryAndTaskSection =
      (metadata.needCss && metadata.needCode) ||
      metadata.needCss ||
      metadata.needCode
        ? "30%"
        : "40%";
    // const widthTheoryAndTaskSection = "30%";
    const marginLeftCodeAndResultSection = isTheoryAndTaskShow ? "1.5%" : "6%";

    // const widthHtmlBox = !metadata.needCss ? "100%" : "59%";
    // const widthCssBox = "40%";

    //
    return (
      <>
        <Header
          blockTasksArr={blockTasksArr}
          currentTaskId={currentTaskId}
          getPrevTask={this.getPrevTask}
          getNextTask={this.getNextTask}
          passed={passed}
          block={block}
          headerTextLogoRef={this.headerTextLogoRef}
          headerTextQuestionRef={this.headerTextQuestionRef}
          getSpecificTask={this.getSpecificTask}
          passedTasks={passedTasks}
          userGameVariable={userGameVariable}
          clientWidth={this.props.clientWidth}
        />
        {isShowErrorEndedLives && <ErrorEndedLives />}

        <main className={styles.main}>
          <article className={styles.mainContent}>
            <ButtonHideTheory
              toggleTheoryAndTask={this.toggleTheoryAndTask}
              isTheoryAndTaskShow={isTheoryAndTaskShow}
            />

            {/* начало секции теории и задания */}
            {isTheoryAndTaskShow && (
              <section
                ref={this.theoryAndTaskRef}
                style={{ width: widthTheoryAndTaskSection }}
              >
                <TheoryAndTaskOrLayout
                  isServerError={isServerError}
                  isTheoryAndTaskShow={isTheoryAndTaskShow}
                  htmlDescription={htmlDescription}
                  cvLink={metadata.cvLink}
                />
              </section>
            )}
            {/* конец секции теории и задания */}

            {/* начало секции кода и результата */}
            <section
              className={styles.codeAndResult_container}
              style={{
                width: widthCodeAndResultSection,
                marginLeft: marginLeftCodeAndResultSection,
              }}
            >
              <CodeEditors
                valueHTML={valueHTML}
                valueCSS={valueCSS}
                handleChangeHMTL={this.handleChangeHMTL}
                handleChangeCSS={this.handleChangeCSS}
                metadata={metadata}
                notEditableHtmlBlocks={notEditableHtmlBlocks}
                notEditableCssBlocks={notEditableCssBlocks}
                htmlHlLines={htmlHlLines}
                cssHlLines={cssHlLines}
                currentTaskId={currentTaskId}
                isRefreshCurrentQuestion={isRefreshCurrentQuestion}
                htmlCursor={htmlCursor}
                cssCursor={cssCursor}
                activityTimeMonitoringInCodeEditor={
                  this.activityTimeMonitoringInCodeEditor
                }
                block={block}
              />

              <TaskButtons
                checkTest={this.checkTest}
                timerEndTime={timerEndTime}
                metadata={metadata}
                openModalRefreshQuestion={this.openModalRefreshQuestion}
                // для анимации первого дня первого вопроса
                blockIndex={blockIndex}
                currentTaskId={currentTaskId}
                blockTasksArr={blockTasksArr}
                showTimer={showTimer}
                hideTimer={this.hideTimer}
                clientWidth={clientWidth}
                isTheoryAndTaskShow={isTheoryAndTaskShow}
                // percentageDoneTask={percentageDoneTask}
                // getNextTask={this.getNextTask}
                openCongratsModal={this.openCongratsModal}
                takeAwayOnePointFromUserАForViewingVideohint={
                  this.takeAwayOnePointFromUserАForViewingVideohint
                }
                token={token}
                userGameVariable={userGameVariable}
              />

              <div
                ref={this.iframeRef}
                className={styles.result_container}
                id="iframe-box"
              >
                <BrowserHeader
                  openBrowserResultModal={this.openBrowserResultModal}
                  openModalRefreshQuestion={this.openModalRefreshQuestion}
                  isMobileViewSelected={isMobileViewSelected}
                  selectMobileView={this.selectMobileView}
                  cancelSelectMobileView={this.cancelSelectMobileView}
                  isTabletViewSelected={isTabletViewSelected}
                  selectTabletView={this.selectTabletView}
                  cancelTabletView={this.cancelTabletView}
                />
                <FrameComponent
                  width={
                    isMobileViewSelected
                      ? "360px"
                      : isTabletViewSelected
                      ? "560px"
                      : "100%"
                  }
                >
                  <div>
                    <style>
                      {compiledValueCss ? compiledValueCss : valueCSS}
                    </style>
                    <div dangerouslySetInnerHTML={resultView} />
                  </div>
                </FrameComponent>
              </div>
            </section>
            {/* конец секции кода и результата */}
          </article>
        </main>
        <Footer
          footerTextRef={this.footerTextRef}
          showModalIntroductionInfo={this.showModalIntroductionInfo}
          showModalShareWithFriend={this.showModalShareWithFriend}
          clientWidth={clientWidth}
        />
        {isCongratsModalOpen && speedResult && (
          <CongratsModal
            onClose={this.closeCongratsModal}
            speedResult={speedResult}
            blockTasksArr={blockTasksArr}
            // blockIndex={blockIndex}
            block={block}
            clientWidth={clientWidth}
            currentTaskId={currentTaskId}
          />
        )}
        {showBrowserResultModal && (
          <BrowserResultModal
            onClose={this.closeBrowserResultModal}
            refreshCurrentQuestion={this.refreshCurrentQuestion}
            valueCSS={valueCSS}
            resultView={resultView}
            openModalRefreshQuestion={this.openModalRefreshQuestion}
            isMobileViewSelected={isMobileViewSelected}
            selectMobileView={this.selectMobileView}
            cancelSelectMobileView={this.cancelSelectMobileView}
            isTabletViewSelected={isTabletViewSelected}
            selectTabletView={this.selectTabletView}
            cancelTabletView={this.cancelTabletView}
            compiledValueCss={compiledValueCss}
          />
        )}
        <ToastContainer style={{ marginTop: "55px" }} />
        {showLoader && <Loader />}
        {showModalRefreshQuestion && (
          <ModalRefreshQuestion
            onClose={this.closeModalRefreshQuestion}
            refreshCurrentQuestion={this.refreshCurrentQuestion}
          />
        )}
        {showModalAutotranslator && (
          <ModalAutotranslator onClose={this.closeModalAutotranslator} />
        )}
        {showModalTaskCriteria && (
          <ModalTaskCriteria
            onClose={this.closeModalTaskCriteria}
            successConditions={successConditions}
            failedConditions={failedConditions}
            taskSuccessfullyPassed={taskSuccessfullyPassed}
            getNextTask={this.getNextTask}
            blockTasksArr={blockTasksArr}
            currentTaskId={currentTaskId}
          />
        )}
        {clientWidth > 900 && (
          <Speedometer onClickSpeedometer={this.showLeftSidePanel} />
        )}
        {isShowLeftSidePanel && (
          <LeftSidePanel
            onClose={this.hideLeftSidePanel}
            block={block}
            showModalIntroductionInfo={this.showModalIntroductionInfo}
            userGameVariable={userGameVariable}
            videoEducation={videoEducation}
            isBonusLinkedinAvailable={isBonusLinkedinAvailable}
          />
        )}
        {userGameVariable.isFirstSiteOpening === "true" ? (
          <ModalIntroductionInfo
            hideModalIntroductionInfo={this.hideModalIntroductionInfo}
            isUserAgreeWithGameRules={isUserAgreeWithGameRules}
            handleChangeUserAgreeWithGameRules={
              this.handleChangeUserAgreeWithGameRules
            }
            userGameVariable={userGameVariable}
            startGameOrContinue={this.startGameOrContinue}
            telegramUserInfo={telegramUserInfo}
            clientWidth={clientWidth}
          />
        ) : null}

        {isShowModalIntroductionInfo && (
          <ModalIntroductionInfo
            hideModalIntroductionInfo={this.hideModalIntroductionInfo}
            isUserAgreeWithGameRules={isUserAgreeWithGameRules}
            handleChangeUserAgreeWithGameRules={
              this.handleChangeUserAgreeWithGameRules
            }
            userGameVariable={userGameVariable}
            startGameOrContinue={this.startGameOrContinue}
            telegramUserInfo={telegramUserInfo}
            clientWidth={clientWidth}
          />
        )}

        {isModalProgressOpen && (
          <ModalProgress
            onClose={this.closeModalProgress}
            userGameVariable={userGameVariable}
          />
        )}

        {isShowModalShareWithFriend && (
          <ModalShareWithFriend
            onClose={this.hideModalShareWithFriend}
            telegramUserInfo={telegramUserInfo}
            currentTaskId={currentTaskId}
          />
        )}
        {isShowModalInvitedFriend && (
          <ModalInvitedFriend onClose={this.hideModalInvitedFriends} />
        )}
        {isModalRefInvited && (
          <ModalRefInvited onClose={this.closeModalRefInvited} />
        )}
      </>
    );
  }
}

export default MainPage;
