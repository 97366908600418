export default {
  header_logoName: "Podstawy HTML i CSS. ",
  header_day: "Dzień ",
  header_question: "Zapytanie ",
  header_modul: "Zajęcia ",
  header_text: "z",
  buttonHideTheory_text: "Ukryj teorię i zadanie",
  congratsModal_title: "Gratulujemy!",
  congratsModal_text1_1: "Z powodzeniem ukończyłeś",
  congratsModal_text1_2: "x zajęcia przygotowujące do zadania domowego!",
  congratsModal_text2: "Twój wynik:",
  congratsModal_text3: "prawidłowych odpowiedzi",
  congratsModal_text5_1: "Ukończyłeś pierwszy dzień ",
  congratsModal_text5_2: " szybciej od pozostałych uczestników! Działaj dalej ",
  congratsModal_text5_3: " szybciej ",
  congratsModal_text5_4: "Teraz masz dostęp do filmiku edukacyjnego Wani Łapy, w którym pokazuje on ",
  congratsModal_text5_5: "jak wykonać wszystkie 10 zadań jedną linijką kodu. ",
  congratsModal_text5_6: "Czas oglądania: 14 minut ",
  congratsModal_text5_7: "Dostęp do filmiku - kliknij przycisk poniżej ",
  congratsModal_text5_8: "Wykonałeś zadanie ",
  congratsModal_text6_1: "Wykonałeś zadania z drugiego dnia i zrobiłeś to ",
  congratsModal_text6_2: "od innych uczestników! Nie zwalniaj tempa! ",
  congratsModal_text6_3: "Dzisiaj razem z Wanią ",
  congratsModal_text6_4: "dokończysz tagi HTML w lewej części swojego planu ",
  congratsModal_text6_5: "i szkielet całego projektu będzie gotowy ",
  congratsModal_text6_6: "Czas oglądania: 11 minut ",
  congratsModal_text6_7: "Nie czekaj i kliknij przycisk poniżej ",
  congratsModal_text7_1: "Ukończyłeś najtrudniejszy Dzień 3 i zrobiłeś to",
  congratsModal_text7_2: "od",
  congratsModal_text7_3: "innych uczestników! To znaczące osiągnięcie!",
  congratsModal_text7_4: "Hurra, tak trzymaj",
  congratsModal_text7_5: "Pora upiększyć prawą część Twojego CV przy pomocy CSS",
  congratsModal_text7_6: "Trzeba się natrudzić, aby osiągnąć wymarzony cel już niedługo",
  congratsModal_text7_7: "Czas oglądania: 32 minuty",
  congratsModal_text7_8: "Dostęp do filmiku - przycisk poniżej",
  congratsModal_text8_1: "Czwarty dzień ukończyłeś",
  congratsModal_text8_2: "od innych uczestników, na horyzoncie już meta",
  congratsModal_text8_3: "Dzisiaj skończymy stylizację lewej części planu",
  congratsModal_text8_4: "Już jutro będziesz u celu, dumny ze swojego linku polecającego, nie zwalniaj tempa",
  congratsModal_text8_5: "Czas oglądania: 12 minut",
  congratsModal_text8_6: "Dostęp do filmiku - przycisk poniżej",
  congratsModal_title_final: "Zwycięstwo, gratulujemy",
  congratsModal_text9_1: "Ukończyłeś swój projekt niesamowicie szybko - ",
  congratsModal_text9_2: "szybciej niż",
  congratsModal_text9_3: "innych uczestników.",
  congratsModal_text9_4: "Jesteśmy z Ciebie bardzo dumni",
  congratsModal_text9_5: "Czekamy z niecierpliwością na Twój link do CV na czacie uczestników",
  congratsModal_text9_6: "I nie wahaj się napisać dzisiaj na wspólnym czacie, jeśli masz jakieś pytania techniczne - w końcu jest tam dużo uczestników, którzy zrobili swoje CV i są gotowi pomóc pozostałym",
  congratsModal_text9_7: "Już niedługo spotkamy się na końcowym webinarium, nie przegap tego",
  congratsModal_text9_8: "Oto Twój bonus,",
  congratsModal_text9_9: "po obejrzeniu którego Twoje CV i profil na Linkedin przyciągną uwagę menedżerów HR najlepszych firm",
  congratsModal_text9_10: "Tę instrukcję stworzyliśmy na podstawie doświadczenia ponad 1000 naszych absolwentów ",
  congratsModal_text9_11: "Kliknij przycisk poniżej, aby się z nim zapoznać",
  ErrorInvalidTokenOrBlock_text: "Nieprawidłowy link.",
  ErrorServerRefresh_text: "Trwają prace techniczne na stronie. Serwer uruchamia się ponownie, odśwież stronę za kilka minut.",
  ErrorServerStub_text: "Trwają prace techniczne na stronie. Spróbuj ponownie później.",
  ErrorStub_text: "Nieprawidłowy link.",
  footer_text: "Polityka prywatności",
  MobileAndTabletStub_text: "Dodaj rozszerzenie do swojej przeglądarki lub znajdź je na pulpicie.",
  ModalAutotranslator_title: "Wyłącz tłumaczenie maszynowe!",
  ModalAutotranslator_text: "W innym wypadku program nie będzie w stanie sprawdzić Twojego zadania.",
  ModalRefreshQuestion_title: "Na pewno chcesz zresetować swój kod?",
  ModalRefreshQuestion_yes: "Tak",
  ModalRefreshQuestion_no: "Nie",
  ModalTaskCriteria_title: "Wynik:",
  ModalTaskCriteria_success: "Ukończono:",
  ModalTaskCriteria_errors: "Błędy:",
  ModalTaskCriteria_buttonContinue: "Kontynuuj",
  ModalTaskCriteria_buttonNextQuestion: "Przejdź do nastepnego pytania",
  ModalTaskCriteria_buttonOk: "OK",
  TaskButtons_btnCheck: "Sprawdź",
  TaskButtons_btnRefresh: "Resetuj",
  markup: "Plan",
  click_picture: "* kliknij obrazek, aby powiększyć",
  Timer_text: "Dostęp do wideowskazówki przez",
  callToastify_taskAlreadySolved: "To zapytanie uzyskało już odpowiedź!",
  callToastify_text1: "W tym zadaniu redaktory HTML i CSS nie są aktywne, używaj tylko redaktora JavaScript",
  callToastify_text2: "W tym zadaniu redaktory HTML i JavaScript nie są aktywne, używaj tylko redaktora CSS",
  callToastify_text3: "W tym zadaniu redaktory CSS i JavaScript nie są aktywne, używaj tylko redaktora HTML",
  callToastify_text4: "W tym zadaniu redaktor HTML nie jest aktywny, używaj tylko redaktora CSS",
  callToastify_text5: "W tym zadaniu redaktor CSS nie jest aktywny, używaj tylko redaktora HTML",
  callToastify_text6: "W tym zadaniu redaktor HTML nie jest aktywny, używaj tylko redaktora JavaScript",
  selectCurrentQuestion_text: "Zapytanie",
  modalPolitics_title: "POLITYKA PRYWATNOŚCI",
  modalPolitics_text: "Dając pozwolenie na sprawdzanie zadań, wyrażasz zgodę na przetwarzanie swoich danych osobowych i otrzymywanie treści marketingowych drogą mailową. Akceptując politykę prywatności potwierdzasz, że zapoznałeś się z prawami podmiotu danych.",
  modalPolitics_subtitle1: "1. GROMADZENIE DANYCH OSOBOWYCH",
  modalPolitics_text1_1: "1.1. Podczas wypełniania formularzu kontaktowego przy rejestracji prosimy o podanie informacji w zakresie niezbędnym do świadczenia przez nas usług.",
  modalPolitics_text1_2: "1.2. Pliki cookies - kiedy klikasz linki, wysyłamy na Twój komputer lub inne urządzenie jeden lub kilka plików cookie. Pliki cookie służą zwiększeniu jakości świadczonych usług: zachowują ustawienia użytkownika.",
  modalPolitics_text1_3: "1.3. Informacja o wejściu - kiedy wchodzisz na stronę, nasze serwery mogą automatycznie zapisywać pewne informacje. Mogą one przechowywać informacje takie jak zapytanie internetowe, adres IP, typ i język przeglądarki, datę i godzinę zapytania. ",
  modalPolitics_subtitle2: "2. OCHRONA DANYCH OSOBOWYCH",
  modalPolitics_text2_1: "2.1. Podejmujemy wszystkie niezbędne działania, aby chronić Twoje dane przed nieautoryzowanym dostępem, zmianą, ujawnieniem lub zniszczeniem. Działania te obejmują wewnętrzne sprawdzanie procesu zbierania danych, przechowywania i przetwarzania, środki bezpieczeństwa, łącznie z prawidłowym szyfrowaniem i działaniem, aby zapewnić ochronę danych i zapobiec nieautoryzowanemu dostępowi. ",
  modalPolitics_subtitle3: "3. ZMIANA POLITYKI PRYWATNOŚCI",
  modalPolitics_text3_1: "3.1. Polityka prywatności może się zmieniać od czasu do czasu. Zmiany wniesione do polityki prywatności będą opublikowane w tym dokumencie. ",
  ScoreRate_text: "Punkty: ",
  ScoreRateTooltip: "Liczba uzyskanych punktów. Za każde wykonane zadanie otrzymujesz 2 punkty. ",
  LivelihoodIndicatorTooltip: "Liczba dostępnych żyć. Tracisz jedno życie, jeśli nie wypełnisz zadania z konkretnego dnia do godz. 18:00 dnia następnego. Jeśli utracisz wszystkie życia, nie będziesz już mógł wykonywać zadań.",
  TaskButtonsVideo_tooltip: "Każda wskazówka kosztuje Cię 1 punkt ",
  LeftSidePanel_title: "Twoje osiągnięcia ",
  LeftSidePanel_progress: "Progres",
  LeftSidePanel_level: "Twój poziom ",
  LeftSidePanel_bonus: "Twoje bonusy",
  LeftSidePanel_nextLevel: "Do następnego poziomu brakuje Ci tyle punktów ",
  bonus: "Bonus ",
  videoLinksToBonus_bonusSoftSkills: "https://www.youtube.com/watch?v=232y1qYaYO4&t=1s",
  videoLinksToBonus_bonusFindJob: "https://www.youtube.com/watch?v=So9BjQpaHKU",
  videoLinksToBonus_linkedin: "https://www.youtube.com/watch?v=yAGRoxXM9EM",
  LeftSidePanel_inviteFriend: "Zaproszeni znajomi:",
  LeftSidePanel_lifes: "Liczba żyć:",
  ModalIntroductionInfo_title: "Zasady gry",
  ModalIntroductionInfo_text1: " zapoznaj się z tymi zasadami #dokładnie. Pomoże Ci to przejść nasz kurs.",
  ModalIntroductionInfo_text0: "Start",
  ModalIntroductionInfo_text2_1: "A więc, obecnie jesteś na ",
  ModalIntroductionInfo_text2_2: "pierwszym poziomie - Nowicjusz (Beginner) - ",
  ModalIntroductionInfo_text2_3: "wypełniając zadania każdego dnia, zdobywasz punkty i możesz dzięki nim otworzyć dostęp do wspaniałych bonusów:",
  ModalIntroductionInfo_text3: "2 poziom - Trainee.",
  ModalIntroductionInfo_text4: "szkolenie od CEO GoIT Antona Czernowa pt.: ",
  ModalIntroductionInfo_text4_1: "“Praca w zespole IT i błędy popełniane podczas wyznaczania celów”. ",
  ModalIntroductionInfo_text4_2: "Przeanalizujemy, na ile ważne jest wyznaczanie dokładnych celów w różnych sferach życia i jak ich liczba wpływa na ",
  ModalIntroductionInfo_text5: "możliwość zostania programistą ",
  ModalIntroductionInfo_text6: "3 poziom - Junior.",
  ModalIntroductionInfo_text7: "instrukcja od Ingi Gadziackiej (EPAM Systems, Senior Talent Acquisition Specialist) pt.: ",
  ModalIntroductionInfo_text7_1: "Podręcznik działań: od czego zacząć poszukiwanie pracy, gdy CV jest już gotowe",
  ModalIntroductionInfo_text7_2: "Inga przygotowała dla Was analizę właściwości najlepszych stron do umieszczania swojego CV i obserwacji sytuacji na rynku pracy + porady dotyczące skutecznego korzystania z każdej z nich ",
  ModalIntroductionInfo_text8_1: "Tych, którzy zakończą kurs ",
  ModalIntroductionInfo_text8_2: "na poziomie Junior i oddadzą swój link ",
  ModalIntroductionInfo_text8_3: "czeka ",
  ModalIntroductionInfo_text8_4: "jeszcze jeden bonus - ",
  ModalIntroductionInfo_text8_5: "dostęp do instrukcji dotyczącej tworzenia i przygotowywania CV i profilu w Linkedln, aby ",
  ModalIntroductionInfo_text8_6: "z powodzeniem zacząć karierę w branży IT. ",
  ModalIntroductionInfo_text8_7: "Tę instrukcję stworzyliśmy na podstawie doświadczenia ponad 1000 naszych absolwentów ",
  ModalIntroductionInfo_text9: "Co musisz zrobić ",
  ModalIntroductionInfo_text10: "Wszystko jest bardzo proste: ",
  ModalIntroductionInfo_text10_1: "rozwiązuj zadania każdego dnia i zbieraj punkty.",
  ModalIntroductionInfo_text10_2: " 1 zadanie = 2 punkty. ",
  ModalIntroductionInfo_text10_3: "Obecnie masz ",
  ModalIntroductionInfo_text10_4: "30 punktów na start, ",
  ModalIntroductionInfo_text10_5: "a Twój poziom to ",
  ModalIntroductionInfo_text10_6: "Beginner. ",
  ModalIntroductionInfo_text10_7: "Aby odblokować pierwszy bonus, ",
  ModalIntroductionInfo_text10_7_1: "poziom Trainee, ",
  ModalIntroductionInfo_text10_7_2: "musisz otrzymać ",
  ModalIntroductionInfo_text10_8: "60 punktów. ",
  ModalIntroductionInfo_text10_9: "Drugi bonus i ",
  ModalIntroductionInfo_text10_10: "poziom Junior kosztują 90 punktów.",
  ModalIntroductionInfo_text10_11: "Aby zawalczyć o trzeci - musisz przejść cały kurs.",
  ModalIntroductionInfo_text11: "Pod koniec każdego dnia otrzymasz dostęp do filmików edukacyjnych Wani Łapy, w których pokazuje on, jak wypełniać zadania “jedną linijką kodu” ",
  ModalIntroductionInfo_text11_1: " W rezultacie, razem stworzycie CV ",
  ModalIntroductionInfo_text12: "Jednak, nie wszystko jest takie proste...",
  ModalIntroductionInfo_text13_1: "Masz tylko 3 życia. ",
  ModalIntroductionInfo_text13_2: "Jeśli nie rozwiążesz zadań do 18:00 następnego dnia, tracisz jedno życie, a z Twojej puli punktów znika 5 punktów.",
  ModalIntroductionInfo_text13_3: "W momencie gdy utracisz ostatnie życie - Game Over, zadania staną się dla Ciebie niedostępne",
  ModalIntroductionInfo_text14: "Wideowskazówki",
  ModalIntroductionInfo_text14_1: "Jeśli czujesz, że pojawiają się trudności, skorzystaj z wideowskazówki. Będziesz mógł ją wziąć przy dowolnym zadaniu.",
  ModalIntroductionInfo_text14_2: "Uwaga: ",
  ModalIntroductionInfo_text14_3: "każda wskazówka kosztuje Cię 1 punkt ",
  TaskButtons_btnVideo: "Wideowskazówka",
  button_share: "Udostępnij znajomym ",
  button_go: "Lecimy!",
  button_watch: "Obejrzyj wideo",
  cool_instruction: "Obejrzyj",
  youtube_1: "https://youtu.be/7pMOogr1h00 ",
  youtube_2: "https://youtu.be/TfU3EAMqVOA",
  youtube_3: "https://youtu.be/It9iiBs6WCg",
  youtube_4: "https://youtu.be/7bnzN-Sdwx0 ",
  youtube_5: "https://youtu.be/yAGRoxXM9EM",
  ButtonShareWithAFriendText: "Udostępnij znajomym ",
  ModalIntroductionInfo_text_15: "Jak jeszcze możesz zdobyć punkty ",
  ModalIntroductionInfo_text15_1: "Zaproś znajomego: jeśli się zarejestruje, obydwoje dostaniecie po 5 punktów. Więcej znajomych - więcej punktów ",
  ModalIntroductionInfo_text16: "Jak monitorować swój progres",
  ModalIntroductionInfo_text16_1: "Prędkościomierz - uniwersalny przycisk z informacją o Twoim progresie. Po jego kliknięciu, możesz zobaczyć:",
  ModalIntroductionInfo_text16_2: "na jakim jesteś obecnie poziomie;",
  ModalIntroductionInfo_text16_3: "ile punktów zdobyłeś już teraz i ile pozostało do następnego poziomu;",
  ModalIntroductionInfo_text16_4: "bonusy, które zdobędziesz przy poziomach Trainee i Junior;",
  ModalIntroductionInfo_text16_5: "życia - ile ich zostało.",
  ModalIntroductionInfo_text17: "Szybki dostęp w panelu górnym ",
  ModalIntroductionInfo_text18: "- Twoja liczba punktów.",
  ModalIntroductionInfo_text19: "- Twoje życia.",
  ModalIntroductionInfo_text20: "Twój przycisk polecający ",
  ModalIntroductionInfo_text21: "to Twój personalny przycisk, pozwalający na zaproszenie do gry. Generuje link polecający, który możesz wysłać znajomym. Pozwoli im to dołączyć do gry, a poza tym - zdobyć 5 punktów (i Ty i Twój znajomy). Rozwiązujcie zadania razem (tylko nie podglądajcie i nie ściągajcie od siebie!) - tak jest ciekawiej ",
  ModalIntroductionInfo_text22: "Jeśli coś przegapiłeś...",
  ModalIntroductionInfo_text23: "Zawsze możesz otworzyć to okno, klikając na znaczek informacji w panelu dolnym.",
  ModalIntroductionInfo_text24: "Zapoznałem/łam się z informacją, wszystko rozumiem",
  toastifyFunction_error: "Zapoznaj się z zasadami gry i kliknij, aby potwierdzić przeczytanie regulaminu!",
  ToastifyFunction_isNotTelegramUser: "Nieprawidłowy token, użytkownik nie ma profilu w Telegramie!",
  ErrorEndedLives_text: "Przykro nam, ale gra się dla Ciebie skończyła. Aby nauczyć się programowania, musisz codziennie ćwiczyć. Nie opanujesz HTML/CSS bez codziennej praktyki.",
  progressTittle: "Gratulujemy ",
  trainee_1: "Osiągnąłeś poziom",
  trainee_2: "I odblokowałeś pierwszy bonus - szkolenie od CEO GoIT Antona Czernowa pt.:",
  trainee_3: "“Praca w zespole IT i błędy popełniane podczas wyznaczania celów”. ",
  trainee_4: "Przeanalizujemy, na ile ważne jest wyznaczanie dokładnych celów w różnych sferach życia i jak ich liczba wpływa na",
  trainee_5: "możliwość zostania programistą ",
  trainee_6: "Skorzystać z bonusu możesz w dowolnym momencie, klikając przycisk Prędkościomierz ",
  trainee_level: "Poziom Trainee ",
  junior_1: "Osiągnąłeś poziom ",
  junior_2: "Hurra, tak trzymać ",
  junior_3: "Osiągnąłeś kolejny poziom. Twój bonus - instrukcja od Ingi Gadziackiej (EPAM Systems, Senior Talent Acquisition Specialist) pt.:",
  junior_4: "Podręcznik działań: od czego zacząć poszukiwanie pracy, gdy CV jest już gotowe ",
  junior_5: "Inga przygotowała dla Ciebie analizę właściwości najlepszych stron do umieszczania swojego CV i obserwacji sytuacji na rynku pracy + porady dotyczące skutecznego korzystania z każdej z nich  ",
  junior_level: "Poziom Junior! ",
  lifeWithdrawalNotification1: "❗️ Z Twojej puli punktów zabrano 1 punkt za wzięcie wskazówki 👌",
  lifeWithdrawalNotification2: "❗️ Minus 1 punkt. 😬 Spróbuj następnym razem bez wskazówki. Wszystko się uda 😊",
  lifeWithdrawalNotification3: "❗️ Straciłeś 1 punkt za skorzystanie ze wskazówki.",
  ModalShareWithFriend_title: "Zaproś znajomych ",
  ModalShareWithFriend_mainText: "Skopiuj tekst z linkiem znajdujące się poniżej, wyślij znajomym lub opublikuj w swoich mediach społecznościowych. Oni również mogą wziąć udział w zabawi ",
  ModalShareWithFriend_mainText2_1: "💥 Bonus: ",
  ModalShareWithFriend_mainText2_2: "jeśli znajomy się zarejestruje, obydwoje otrzymacie po 5 punktów.",
  ModalShareWithFriend_refText1: "Biorę udział w szkoleniu od GoIT. Już niedługo stworzę swój pierwszy projekt - stronę z CV.",
  ModalShareWithFriend_refText2: "Chcesz spróbować programowania stron internetowych i zapoznać się z podstawami html i css? Dołącz do szkolenia klikając mój link i otrzymaj dodatkowe punkty, które pozwolą Ci szybciej odblokować dostęp do bonusów.",
  ModalInvitedFriend_text: "🔥 Super, Twój znajomy dołączył do gry. Do Twojej puli punktów dodano 5 punktów.",
  ModalRefInvited_text: "💥 Gratulujemy! Otrzymałeś 5 punktów za zaproszenie znajomego.",
  LeftSidePanel_video: "Materiały edukacyjne:",
  video: "Wideo ",
  taskSuccessesNotification1: "Gratulujemy! Zdobyłeś 2 punkty. ",
  taskSuccessesNotification2: "Wspaniale! Zdobyłeś nowe punkty. ",
  taskSuccessesNotification3: "Plus 2 punkty. Tak trzymać!",
  taskSuccessesNotification4: "Hurra! Więcej punktów do Twojej puli!",
  taskSuccessesNotification5: "Hurra, dodatkowe punkty!",
  taskSuccessesNotification6: "Twoja pozycja w rankingu rośnie i rośnie!",
  taskSuccessesNotification7: "Plus 2 punkty, niedługo bonus!",
  taskSuccessesNotification8: "Juhu, niedługo nowy poziom!",
  taskSuccessesNotification9: "Tak trzymać! Więcej punktów do rankingu! ",
  taskSuccessesNotification10: "I to jest tempo, brawo!",
  congrats_preTrainee_1: "Jesteś bardzo blisko ",
  congrats_preTrainee_2: "Niedługo otrzymasz dostęp do ",
  congrats_preTrainee_3: "wideoinstrukcji 🔥",
  congrats_preJunior_1: "Niedługo otrzymasz dostęp do ",
  congrats_preJunior_2: "najfajniejszego bonusu poziomu Junior! 😎",
  congrats_preJunior_3: "Zostało tylko kilka zadań do następnego poziomu. 😉 Tak trzymaj! 💪",
};
