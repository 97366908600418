export default {
  header_logoName: "Basics of HTML and CSS ",
  header_day: "Day ",
  header_question: "Question",
  header_modul: "Lesson",
  header_text: "out of",
  buttonHideTheory_text: "Hide theory and lesson",
  congratsModal_title: "Congratulations!",
  congratsModal_text1_1: "You have successfully completed",
  congratsModal_text1_2: "th lesson of preparation for your homework!",
  congratsModal_text2: "Your result:",
  congratsModal_text3: "correct answers",
  congratsModal_text5_1: "You have completed Day 1 and did it ",
  congratsModal_text5_2: "than other players! Keep it going ",
  congratsModal_text5_3: "faster ",
  congratsModal_text5_4:
    "Now you have access to Vanya Lapa's training video, where he shows ",
  congratsModal_text5_5: "how to do all 10 tasks with one line of code.",
  congratsModal_text5_6: "Watch time: 14 minutes",
  congratsModal_text5_7: "Access the video by clicking the button below",
  congratsModal_text5_8: "You have completed the task ",
  congratsModal_text6_1: "You have completed Day 2 tasks and did it ",
  congratsModal_text6_2: "than other players! Do not slow down!",
  congratsModal_text6_3: "Today, you and Vanya ",
  congratsModal_text6_4:
    "will complete HTML tags in the left part of the layout, ",
  congratsModal_text6_5: "and the project's 'skeleton' will be ready",
  congratsModal_text6_6: "Watch time: 11 minutes",
  congratsModal_text6_7: "Do not wait and click the button below ",
  congratsModal_text7_1:
    "You have completed the most difficult Day 3 and did it ",
  congratsModal_text7_2: "than ",
  congratsModal_text7_3: "of the players! This is a landmark achievement ",
  congratsModal_text7_4: "Hurray, keep it up ",
  congratsModal_text7_5:
    "It is time to make the right part of your CV beautiful with CSS ",
  congratsModal_text7_6:
    "You have to work hard to get the desired result very soon",
  congratsModal_text7_7: "Watch time: 32 minutes",
  congratsModal_text7_8: "Access the video by clicking the button below",
  congratsModal_text8_1: "You have completed Day 4 and did it ",
  congratsModal_text8_2:
    "than other players. You can see the finish flag on the horizon ",
  congratsModal_text8_3:
    "Today you will complete styling the left part of your layout ",
  congratsModal_text8_4:
    "Tomorrow you will be there admiring your personal link to the project, so do not slow down",
  congratsModal_text8_5: "Watch time: 12 minutes",
  congratsModal_text8_6:
    "Click the button below to access this well-deserved video",
  congratsModal_title_final: "This is an absolute victory, congratulations",
  congratsModal_text9_1:
    "You have completed the project with incredible success,",
  congratsModal_text9_2: "than",
  congratsModal_text9_3: "of the players.",
  congratsModal_text9_4: "We are very, very proud of you",
  congratsModal_text9_5:
    "We are waiting for the link to your CV in the chat of marathon players",
  congratsModal_text9_6:
    "Do not hesitate to join the general chat today if you have technical questions. There you will find many marathon players who have submitted their CVs and are ready to help others",
  congratsModal_text9_7: "We will soon meet at the final webinar, do not miss",
  congratsModal_text9_8: "Here is your precious bonus.",
  congratsModal_text9_9:
    "Watch it, and your CV and LinkedIn page will attract the attention of HR managers from top companies",
  congratsModal_text9_10:
    "To develop these instructions, we used the employment experience of more than 1000 of our graduates",
  congratsModal_text9_11: "Click the button below to explore",
  ErrorInvalidTokenOrBlock_text: "Invalid link.",
  ErrorServerRefresh_text:
    "The server is under maintenance. The server is rebooting, refresh the page in 1-2 minutes.",
  ErrorServerStub_text:
    "The server is under maintenance. Please try again later.",
  ErrorStub_text: "Invalid link.",
  footer_text: "Privacy policy",
  MobileAndTabletStub_text: "Expand your browser or access from your desktop.",
  ModalAutotranslator_title: "Disable automatic translation !!!",
  ModalAutotranslator_text:
    "Otherwise the program will be unable to check your task.",
  ModalRefreshQuestion_title: "Are you sure you want to reset your code?",
  ModalRefreshQuestion_yes: "Yes",
  ModalRefreshQuestion_no: "No",
  ModalTaskCriteria_title: "Result:",
  ModalTaskCriteria_success: "Completed:",
  ModalTaskCriteria_errors: "Errors:",
  ModalTaskCriteria_buttonContinue: "Continue",
  ModalTaskCriteria_buttonNextQuestion: "Go to the next question",
  ModalTaskCriteria_buttonOk: "OK",
  TaskButtons_btnCheck: "Check",
  TaskButtons_btnRefresh: "Reset",
  markup: "Layout",
  click_picture: "* click on the image to enlarge",
  Timer_text: "Access to video tip via",
  callToastify_taskAlreadySolved: "This question has already been answered!",
  callToastify_text1:
    "In this task, HTML and CSS editors are not active, use only JavaScript editor",
  callToastify_text2:
    "In this task, HTML and JavaScript editors are not active, use only CSS editor",
  callToastify_text3:
    "In this task, CSS and JavaScript editors are not active, use only HTML editor",
  callToastify_text4:
    "In this task, HTML editor is not active, use only CSS editor",
  callToastify_text5:
    "In this task, CSS editor is not active, use only HTML editor",
  callToastify_text6:
    "In this task, HTML editor is not active, use only JavaScript editor",
  selectCurrentQuestion_text: "Question",
  modalPolitics_title: "PRIVACY POLICY",
  modalPolitics_text:
    "By agreeing to have your tasks checked, you consent to the processing of personal data, to the communication channels we have proposed and to receiving mailings and commercial offers. By accepting this policy, you also confirm that you have read the list of your rights as a data subject.",
  modalPolitics_subtitle1: "1. COLLECTING PERSONAL INFORMATION",
  modalPolitics_text1_1:
    "1.1. When filling in the contact forms during the sign up process, please share your personal information to the extent necessary for us to provide our services.",
  modalPolitics_text1_2:
    "1.2. Cookies: When you click on links, we store one or more cookies on your computer or other device. Cookies are used in order to improve the quality of the services provided, i.e. to save the user's preferences.",
  modalPolitics_text1_3:
    "1.3. Visit Information: When you access the site, our servers may automatically record certain data. These server logs may store information such as your web query, IP address, browser type and language, query date and time.",
  modalPolitics_subtitle2: "2. PROTECTING INFORMATION",
  modalPolitics_text2_1:
    "2.1. We take all the necessary measures to protect data from unauthorized access, alteration, disclosure or destruction. These measures include internal checks of data collection, storage and processing, and security measures, including appropriate encryption and actions to ensure the physical security of data to prevent unauthorized access.",
  modalPolitics_subtitle3: "3. CHANGING THIS PRIVACY POLICY",
  modalPolitics_text3_1:
    "3.1. This privacy policy may change from time to time. Changes to this privacy policy will be published in this document.",
  ScoreRate_text: "Points:",
  ScoreRateTooltip:
    "Number of points earned. For each completed task, you receive 2 points. By collecting points, you get access to new levels and new bonuses. Learn more about bonuses by clicking the Info button.",
  LivelihoodIndicatorTooltip:
    "Number of lives remaining. If you do not complete the task of the day until 18:00 [the next day], you will lose one life. If you lose all your lives, you will not be able to do more tasks.",
  TaskButtonsVideo_tooltip: "Each tip costs you 1 point",
  LeftSidePanel_title: "Your achievements",
  LeftSidePanel_progress: "Progress:",
  LeftSidePanel_level: "Your level:",
  LeftSidePanel_bonus: "Your bonuses:",
  LeftSidePanel_nextLevel: "Points to reach the next level:",
  bonus: "Bonus",
  videoLinksToBonus_bonusSoftSkills:
    "https://www.youtube.com/watch?v=232y1qYaYO4&t=1s",
  videoLinksToBonus_bonusFindJob: "https://www.youtube.com/watch?v=So9BjQpaHKU",
  videoLinksToBonus_linkedin: "https://www.youtube.com/watch?v=yAGRoxXM9EM",
  LeftSidePanel_inviteFriend: "Referred friends:",
  LeftSidePanel_lifes: "Available lives:",
  ModalIntroductionInfo_title: "Marathon rules",
  ModalIntroductionInfo_text1:
    "read these rules #carefully. This will help you complete our marathon game.",
  ModalIntroductionInfo_text0: "Start",
  ModalIntroductionInfo_text2_1: "Now you are at",
  ModalIntroductionInfo_text2_2: "Level 1 - Beginner.",
  ModalIntroductionInfo_text2_3:
    "Do new tasks every day, earn points and receive access to cool bonuses: ",
  ModalIntroductionInfo_text3: "Level 2 - Trainee.",
  ModalIntroductionInfo_text4:
    "Master class from GoIT's CEO Anton Cherny on the topic ",
  ModalIntroductionInfo_text4_1: "Work in IT teams and goal setting mistakes",
  ModalIntroductionInfo_text4_2:
    "You will learn about the importance of having clear goals in various areas of your life and how they affect",
  ModalIntroductionInfo_text5: "your chances to become a developer",
  ModalIntroductionInfo_text6: "Level 3 - Junior.",
  ModalIntroductionInfo_text7:
    "Instructions from Inga Gadyatskaya (EPAM Systems, Senior Talent Acquisition Specialist) on the topic",
  ModalIntroductionInfo_text7_1:
    "Guide to action: How to start looking for a job after your CV is ready",
  ModalIntroductionInfo_text7_2:
    "Inga will offer an overview of key job search resources and life hacks for making the most out of them",
  ModalIntroductionInfo_text8_1: "Those who finish the marathon",
  ModalIntroductionInfo_text8_2: "in Junior status and submit their links",
  ModalIntroductionInfo_text8_3: "will receive",
  ModalIntroductionInfo_text8_4: "another bonus,",
  ModalIntroductionInfo_text8_5:
    "access to instructions on how to create a CV and a LinkedIn profile for",
  ModalIntroductionInfo_text8_6:
    "a successful career start and employment in IT.",
  ModalIntroductionInfo_text8_7:
    "To develop these instructions, we used the employment experience of more than 1000 of our graduates",
  ModalIntroductionInfo_text9: "What you need to do",
  ModalIntroductionInfo_text10: "Everything is simple:",
  ModalIntroductionInfo_text10_1:
    "complete new tasks every day and earn points.",
  ModalIntroductionInfo_text10_2: "1 task = 2 points.",
  ModalIntroductionInfo_text10_3: "Now you have",
  ModalIntroductionInfo_text10_4: "30 starting points",
  ModalIntroductionInfo_text10_5: "and your level is",
  ModalIntroductionInfo_text10_6: "Beginner.",
  ModalIntroductionInfo_text10_7: "To access the first bonus,",
  ModalIntroductionInfo_text10_7_1: "Trainee level,",
  ModalIntroductionInfo_text10_7_2: "you need to get",
  ModalIntroductionInfo_text10_8: "60 points.",
  ModalIntroductionInfo_text10_9: "The second bonus and",
  ModalIntroductionInfo_text10_10: "the Junior level cost 90 points.",
  ModalIntroductionInfo_text10_11:
    "To receive the third one, you need to go all the way to the end.",
  ModalIntroductionInfo_text11:
    "At the end of each day, you will be able to watch Vanya Lapa's training videos, where he shows how to do tasks with just 'one line of code'",
  ModalIntroductionInfo_text11_1: "In the end, you will make a CV together",
  ModalIntroductionInfo_text12: "However, it is not all that simple...",
  ModalIntroductionInfo_text13_1: "You only have 3 lives.",
  ModalIntroductionInfo_text13_2:
    "If you do not complete the task of the day until 18:00 the next day, you will lose one life and 5 points.",
  ModalIntroductionInfo_text13_3:
    "After you lose your last life, the game will be over, and our tasks will become unavailable for you",
  ModalIntroductionInfo_text14: "Video tips",
  ModalIntroductionInfo_text14_1:
    "If you face difficulties, use a video tip. You can take it for any task",
  ModalIntroductionInfo_text14_2: "However, please note:",
  ModalIntroductionInfo_text14_3: "each tip costs you 1 point",
  TaskButtons_btnVideo: "Video tip",
  button_share: "Share with a friend",
  button_go: "Let's go!",
  button_watch: "Watch video",
  cool_instruction: "Watch",
  youtube_1: "https://youtu.be/7pMOogr1h00",
  youtube_2: "https://youtu.be/TfU3EAMqVOA ",
  youtube_3: "https://youtu.be/It9iiBs6WCg",
  youtube_4: "https://youtu.be/7bnzN-Sdwx0",
  youtube_5: "https://youtu.be/yAGRoxXM9EM",
  ButtonShareWithAFriendText: "Share with a friend",
  ModalIntroductionInfo_text_15: "Other ways to earn points",
  ModalIntroductionInfo_text15_1:
    "Invite a friend: as soon as your friend signs up, both of you will be awarded 5 points. More friends, more points ",
  ModalIntroductionInfo_text16: "How to track your progress",
  ModalIntroductionInfo_text16_1:
    "is a universal button with information about your progress. By clicking it, you can see:",
  ModalIntroductionInfo_text16_2: "what level you are at;",
  ModalIntroductionInfo_text16_3:
    "how many points you have earned and how much is left until the next level;",
  ModalIntroductionInfo_text16_4:
    "your bonuses when you reach the Trainee and Junior levels;",
  ModalIntroductionInfo_text16_5: "your lives, how many is left.",
  ModalIntroductionInfo_text17: "Quick access in the top bar",
  ModalIntroductionInfo_text18: "- number of points.",
  ModalIntroductionInfo_text19: "- your lives.",
  ModalIntroductionInfo_text20: "Your referral button",
  ModalIntroductionInfo_text21:
    "your personal button to invite friends. It generates a referral link that you can share with your friends. They will be able to join the game, and each of you will earn 5 points. Do tasks together (just do not pry!), this is more exciting.",
  ModalIntroductionInfo_text22: "If you missed something...",
  ModalIntroductionInfo_text23:
    "You can always open this window again by clicking the information icon in the bottom bar.",
  ModalIntroductionInfo_text24:
    "I have read and understood the rules of the game",
  toastifyFunction_error:
    "First, read the rules of the game and tick the box to confirm it!",
  ToastifyFunction_isNotTelegramUser:
    "Incorrect token, the user is not a Telegram user!",
  ErrorEndedLives_text:
    "We are sorry, but the game is over for you. To learn programming, you need to practice regularly. You will not be able to learn HTML/CSS without practicing every day",
  progressTittle: "Congratulations",
  trainee_1: "You have reached",
  trainee_2:
    "and got access to the first bonus, a master class from GoIT's CEO Anton Cherny on the topic",
  trainee_3: "Work in IT teams and goal setting mistakes",
  trainee_4:
    "You will learn about the importance of having clear goals in various areas of your life and how they affect",
  trainee_5: "your chances to become a developer",
  trainee_6:
    "You can access the bonus at any time by clicking the Speedometer button",
  trainee_level: "the Trainee level.",
  junior_1: "You have now reached",
  junior_2: "Hurray, keep it up",
  junior_3:
    "You have reached the next level. As a bonus, you can watch instructions from Inga Gadyatskaya (EPAM Systems, Senior Talent Acquisition Specialist) on the topic",
  junior_4:
    "Guide to action: How to start looking for a job after your CV is ready",
  junior_5:
    "Inga will offer an overview of key job search resources and life hacks for making the most out of them",
  junior_level: "the Junior level!",
  lifeWithdrawalNotification1:
    "❗️We deduct 1 point from your account for using the tip 👌",
  lifeWithdrawalNotification2:
    "❗️Minus 1 point 😬 Next time try without a hint. Everything will work out fine 😊",
  lifeWithdrawalNotification3: "❗️You have lost 1 point for using the hint.",
  ModalShareWithFriend_title: "Share with a friend",
  ModalShareWithFriend_mainText:
    "Copy the text with the link below, send it to your friends or publish on social media. They can play, too",
  ModalShareWithFriend_mainText2_1: "💥 Bonus: ",
  ModalShareWithFriend_mainText2_2:
    "when your friend signs up, both of you will receive 5 points.",
  ModalShareWithFriend_refText1:
    "I am playing the GoIT marathon. Very soon, I will complete my first project, a CV page.",
  ModalShareWithFriend_refText2:
    "Do you want to try web programming and learn the basics of HTML and CSS? Follow my link to receive additional points and quickly get access to bonuses.",
  ModalInvitedFriend_text:
    "🔥 Cool, your friend has joined the game. Your account has been credited with 5 points.",
  ModalRefInvited_text:
    "💥 Congratulations! You have earned 5 additional points for participating in the referral system.",
  LeftSidePanel_video: "Educational materials:",
  video: "Video",
  taskSuccessesNotification1: "Congratulations! You have earned 2 points.",
  taskSuccessesNotification2: "Excellent! You have earned new points.",
  taskSuccessesNotification3: "Plus 2 points. Way to go!",
  taskSuccessesNotification4: "Hooray! Get more points into your account!",
  taskSuccessesNotification5: "Hooray, extra points!",
  taskSuccessesNotification6: "Your rating is getting better and better!",
  taskSuccessesNotification7: "+2 points, your bonus is close!",
  taskSuccessesNotification8: "Woohoo! The next level is coming soon!",
  taskSuccessesNotification9: "Way to go! More points to the rating!",
  taskSuccessesNotification10: "So fast! Well done!",
  congrats_preTrainee_1: "You are very close to ",
  congrats_preTrainee_2: "You will soon get access to ",
  congrats_preTrainee_3: "the video tutorial 🔥 ",
  congrats_preJunior_1: "Soon you will have access to ",
  congrats_preJunior_2: "the coolest feature of the Junior level! 😎",
  congrats_preJunior_3:
    "Only a few tasks left until the next level 😉 Step it up 💪",
};
